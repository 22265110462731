import { Route, Link } from "react-router-dom";
import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
//import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import Button from '@material-ui/core/Button';
import Box from '@mui/material/Box';
// import Button from '@mui/material/Button';
// import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

import Forms from "../formsList.js";
import myOrders from "../myOrders.js";
import test from "../test.js";
import addUser from "../addUser.js";
import assign from "../assign.js";
import create from "../create.js";
import Users from "../recordList.js";
import Dashboard from "../dashboard/Dashboard";
import Register from "../auth/Register";
import orderDetails from "../orderDetails";
import updateProfile from "../updateProfile";
import updateOrder from "../updateOrder";
import updatePassword from "../updatePassword";
import forgotPassword from "../forgotPassword";
import Login from "../auth/Login";
// import Login from '../auth/Login';
import { logoutUser } from "../../actions/authActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { useMediaQuery } from 'react-responsive'

import jwt_decode from "jwt-decode";
// const drawerWidth = 240;
// const history = createBrowserHistory();


const drawerWidth = 200;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));


//export default connect(mapStateToProps)(PrivateRoute);

const PersistentDrawerLeft = ({ component: Component, auth, logoutUser }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [openModal, setOpen1] = React.useState(false);
  const handleOpen = () => setOpen1(true);
  const handleClose = () => setOpen1(false);

  const style = {
    position: 'absolute',
    top: '55px',
    right: '15px',
    // transform: 'translate(-15%, -50%)',
    width: 320,
    bgcolor: 'background.paper',
    border: 'none !important',
    padding: '10px',
    textAlign: 'center',
    borderRadius: '7px',
    maxHeight: "85% !important",
    overflow: "auto"
    // boxShadow: 14,
    // p: 2,
  };

  const { user } = auth;
  // console.log("usssssss navbar====>>>>>", user);
  var profilePictureLink = user.profile_pic;
  if (user.profile_pic) {
    if (!user.profile_pic.includes('http')) {
      profilePictureLink = '../../uploads/' + user.profile_pic;
    }
  }
  var index = 0;
  var path_arr = (window.location.pathname).split("/");
  console.log("path_arr==> ", path_arr)
  var token = [];
  if (path_arr.length > 2) {
    index = path_arr[1];
  } else if (!path_arr[1].includes('orderDetails')) {
    window.location.href = window.location.origin + '/0/dashboard'
  }
  if (localStorage.jwtToken) {
    token = JSON.parse(localStorage.jwtToken);
  } else {
    if (path_arr[2] !== 'login' && !path_arr[1].includes('orderDetails'))
      window.location.href = window.location.origin + '/' + index + "/login";
  }
  if (index >= token.length)
    index = 0;
  const logoutUserHere = () => {
    // alert("logout");
    localStorage.removeItem("jwtToken");
    logoutUser();
    window.location.href = "./login";
  }
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const isMobileScreen = useMediaQuery({ query: '(max-width: 500px)' })
  const handleDrawerCloseIn = () => {
    if (isMobileScreen)
      setOpen(false);
  };

  // const isMobileScreen = useMediaQuery({ query: '(max-width: 500px)' })

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" >
            Menu
          </Typography>
          <Typography variant="h6" style={{ float: "right", right: "30px", position: "absolute", maxHeight: "40px", overflow: "hidden" }}>
            {auth.isAuthenticated && user.role !== "Admin" && <Link to="./dashboard" > <img style={{ height: "40px", borderRadius: "50%" }} src={profilePictureLink} onError={(e) => { e.target.onerror = null; e.target.src = '../../images/test.png' }} alt={user.name} /></Link>}
            {auth.isAuthenticated && user.role === "Admin" && <span style={{ color: "white", cursor: "pointer" }} onClick={handleOpen}><img style={{ height: "40px", borderRadius: "50%" }} src={profilePictureLink} onError={(e) => { e.target.onerror = null; e.target.src = '../../images/test.png' }} alt={user.name} /></span>}
            {auth.isAuthenticated && user.role === "Admin" &&
              <Modal
                open={openModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  {token.map((data, rowIndex) => {
                    // console.log("===data====", data);
                    const decoded = jwt_decode(data);
                    // console.log("===decoded====", decoded);
                    var piclink = decoded.profile_pic;
                    if (decoded.profile_pic) {
                      if (!decoded.profile_pic.includes('http')) {
                        piclink = '../../uploads/' + decoded.profile_pic;
                      }
                    }
                    if (rowIndex < 1)
                      return (
                        <Typography id="modal-modal-title" variant="h6" component="h2" style={{ display: "initial" }}>
                          <img style={{ maxHeight: "100px", borderRadius: "50%" }} src={piclink} alt={decoded.name} onError={(e) => { e.target.onerror = null; e.target.src = '../../images/test.png' }} />
                          <br />
                          <span style={{ display: "grid" }}>{decoded.name}</span>
                          <span style={{ fontSize: "smaller", display: "grid" }}>{decoded.email}</span>

                          <Button style={{ border: "1px solid", borderRadius: "10px" }}><a onClick={handleClose} style={{ display: "table-cell", color: "initial", fontSize: "initial" }} href={"/" + rowIndex + "/dashboard"} target="_blank">My Profile</a>
                          </Button>
                          <hr />
                          {/* <Link onClick={handleClose} to={"/" + rowIndex + "/dashboard"} >{decoded.name}</Link> */}
                        </Typography>

                      );
                    else
                      return (
                        <Typography id="modal-modal-title" variant="h6" component="h2" style={{ display: "initial" }}>
                          <a onClick={handleClose} style={{ display: "table-cell", color: "initial", fontSize: "initial" }} href={"/" + rowIndex + "/dashboard"} target="_blank">
                            <div style={{ float: "left" }}><img style={{ maxHeight: "45px", borderRadius: "50%" }} src={piclink} onError={(e) => { e.target.onerror = null; e.target.src = '../../images/test.png' }} alt="pic" />&nbsp; &nbsp; </div><div style={{ float: "left" }}>
                              <span>{decoded.name}</span><br />
                              <span style={{ fontSize: "smaller" }}>{decoded.email}</span></div>
                          </a>

                          <br />
                        </Typography>

                      );
                  })}
                  <Typography id="modal-modal-title" variant="h6" component="h2" style={{ display: "initial" }}>
                    <a onClick={handleClose} style={{ display: "table-cell", color: "initial", fontSize: "initial" }} href={"/" + token.length + "/login"} target="_blank">Add another account</a>
                  </Typography>
                  {/* <Box sx={style}>
                  <Typography id="modal-modal-title" variant="h6" component="h2">
                    Text in a modal
                  </Typography>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
                  </Typography> */}
                </Box>
              </Modal>}
          </Typography>
        </Toolbar>
      </AppBar >
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}

      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <Divider />
        {auth.isAuthenticated && user.role !== "Guest" &&
          <List>
            <ListItem onClick={handleDrawerCloseIn} button component={Link} to={"/" + index + "/dashboard"} >
              <ListItemText>Profile</ListItemText>
            </ListItem>
            {user.role === "Admin" && <ListItem onClick={handleDrawerCloseIn} button component={Link} to={"/" + index + "/"} >
              <ListItemText>Users</ListItemText>
            </ListItem>
            }
            {user.role !== "Hero" && <ListItem onClick={handleDrawerCloseIn} button component={Link} to={"/" + index + "/forms"} >
              <ListItemText>Orders</ListItemText>
            </ListItem>}
            {
              user.role === "Hero" && <ListItem onClick={handleDrawerCloseIn} button component={Link} to={"/" + index + "/myOrders"} >
                <ListItemText>Orders</ListItemText>
              </ListItem >}
            {
              ( user.role === "Admin") && <ListItem onClick={handleDrawerCloseIn} button component={Link} to={"/" + index + "/assign"} >
                <ListItemText>Assign Orders</ListItemText>
              </ListItem >}
            {/* <ListItem onClick={handleDrawerCloseIn} button component={Link} to={"/" + token.length + "/login"} >
              <ListItemText>Add Anothoer Account</ListItemText>
            </ListItem> */}
            <ListItem onClick={handleDrawerCloseIn} button >
              <ListItemText onClick={logoutUserHere}>Logout</ListItemText>
            </ListItem>
          </List >
        }{
          user.role === "Guest" &&
          <List>
            <ListItem onClick={handleDrawerCloseIn} button component={Link} to={"/" + index + "/dashboard"} >
              <ListItemText>Profile</ListItemText>
            </ListItem>
            {/* <ListItem onClick={handleDrawerCloseIn} button component={Link} to={"/" + token.length + "/login"} >
              <ListItemText>Add Anothoer Account</ListItemText>
            </ListItem> */}
            <ListItem onClick={handleDrawerCloseIn} button >
              <ListItemText onClick={logoutUserHere}>Logout</ListItemText>
            </ListItem>
          </List >
        }
        {
          !auth.isAuthenticated &&
          <List>
            <ListItem onClick={handleDrawerCloseIn} button component={Link} to={"/" + index + "/login"} >
              <ListItemText>Login</ListItemText>
            </ListItem>
            <ListItem onClick={handleDrawerCloseIn} button component={Link} to={"/" + index + "/register"} >
              <ListItemText>Register</ListItemText>
            </ListItem >
          </List >
        }
        <Divider />

      </Drawer >
      {
        auth.isAuthenticated && user.role !== "Guest" &&
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: open,
          })}
        >
          <div className={classes.drawerHeader} />
          <Route exact path="/:index/dashboard" component={Dashboard} />
          <Route exact path="/:index/forms" component={Forms} />
          <Route exact path="/:index/myOrders" component={myOrders} />
          <Route exact path="/:index/test" component={test} />
          <Route exact path="/:index/assign" component={assign} />
          <Route exact path="/:index/create" component={create} />
          <Route exact path="/:index/addUser" component={addUser} />
          <Route exact path="/:index/login" component={Login} />
          <Route exact path="/:index/register" component={Register} />
          <Route path="/orderDetails" component={orderDetails} />
          <Route exact path="/:index/sendMessage" component={orderDetails} />
          <Route exact path="/:index/updateProfile" component={updateProfile} />
          <Route exact path="/:index/updateOrder" component={updateOrder} />
          <Route exact path="/:index/updatePassword" component={updatePassword} />
          <Route exact path="/:index/forgotPassword" component={forgotPassword} />
          {user.role === "Admin" && <Route exact path="/:index/" component={Users} />}
        </main>
      } {
        auth.isAuthenticated && user.role === "Guest" &&
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: open,
          })}
        >
          <div className={classes.drawerHeader} />
          <Route exact path="/:index/dashboard" component={Dashboard} />
          <Route exact path="/:index/login" component={Login} />
          <Route exact path="/:index/register" component={Register} />
          <Route exact path="/:index/updateProfile" component={updateProfile} />
          <Route exact path="/:index/updatePassword" component={updatePassword} />
        </main>
      }
      {
        !auth.isAuthenticated &&
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: open,
          })}
        >
          <div className={classes.drawerHeader} />
          <Route exact path="/:index/login" component={Login} />
          <Route exact path="/:index/register" component={Register} />
          <Route exact path="/:index/forgotPassword" component={forgotPassword} />
          <Route path="/orderDetails" component={orderDetails} />
        </main>
      }
    </div >
  );
}

PersistentDrawerLeft.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool
};

const mapStateToProps = state => ({
  auth: state.auth,
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { logoutUser })(PersistentDrawerLeft);
