import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { registerUser } from "../actions/authActions";
import classnames from "classnames";
import Button from '@material-ui/core/Button';
import Select from 'react-select';
import InputLabel from '@material-ui/core/InputLabel';

var file = "";

class Register extends Component {
    constructor() {
        super();
        this.state = {
            name: "",
            email: "",
            password: "",
            password2: "",
            role: "",
            profile_pic: "",
            errors: {}
        };
    }

    componentDidMount() {
        // If logged in and user navigates to Register page, should redirect them to dashboard

        // if (this.props.auth.isAuthenticated) {
        //     this.props.history.push("/dashboard");
        //     // const { user } = this.props.auth;
        //     // if (user.role === "Guest") {
        //     //   this.props.history.push("/dashboard");
        //     // } else if(user.role !== "Admin") {
        //     //   this.props.history.push("/forms");
        //     // }else{
        //     //   this.props.history.push("/");
        //     // }
        // }
        console.log("history", this.props.history);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
    }

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    handlePhoto = e => {
        file = e.target.files[0];
        this.setState({ profile_pic: e.target.files[0] })
    }

    setRole = (optval) => {

        this.setState({ role: optval });

    }

    onSubmit = e => {
        e.preventDefault();
        // const newUser = {
        //     name: this.state.name,
        //     email: this.state.email,
        //     password: this.state.password,
        //     password2: this.state.password2,
        //     role: this.state.role
        // };
        const data = new FormData();
        data.append("avatar", this.state.profile_pic);
        data.append("name", this.state.name);
        data.append("email", this.state.email);
        data.append("password", this.state.password);
        data.append("password2", this.state.password2);
        data.append("role", this.state.role);
        if (this.state.profile_pic)
            data.append("profile_pic", "yes");
        else
            data.append("profile_pic", "");

        this.props.registerUser(data, this.props.history);
    };

    render() {
        const { errors } = this.state;

        return (
            <div className="container">
                <div className="row">
                    <div className="col s8 offset-s2">
                        <Link to="./" className="btn-flat waves-effect">
                            <i className="material-icons left">keyboard_backspace</i> Back
                        </Link>
                        <div className="col s12" style={{ paddingLeft: "11.250px" }}>
                            <h4>
                                <b>Add New User</b>
                            </h4>
                            {/* <p className="grey-text text-darken-1">
                                Already have an account? <Link to="./login">Log in</Link>
                            </p> */}
                        </div>
                        <form noValidate onSubmit={this.onSubmit}>
                            <div className="input-field col s12">
                                <input
                                    onChange={this.onChange}
                                    value={this.state.name}
                                    error={errors.name}
                                    id="name"
                                    type="text"
                                    className={classnames("", {
                                        invalid: errors.name
                                    })}
                                />
                                <label htmlFor="name">Name</label>
                                <span className="red-text">{errors.name}</span>
                            </div>
                            <div className="input-field col s12">
                                <input
                                    onChange={this.onChange}
                                    value={this.state.email}
                                    error={errors.email}
                                    id="email"
                                    type="email"
                                    className={classnames("", {
                                        invalid: errors.email
                                    })}
                                />
                                <label htmlFor="email">Email</label>
                                <span className="red-text">{errors.email}</span>
                            </div>
                            <div className="input-field col s12">
                                <input
                                    onChange={this.onChange}
                                    value={this.state.password}
                                    error={errors.password}
                                    id="password"
                                    type="password"
                                    className={classnames("", {
                                        invalid: errors.password
                                    })}
                                />
                                <label htmlFor="password">Password</label>
                                <span className="red-text">{errors.password}</span>
                            </div>
                            <div className="input-field col s12">
                                <input
                                    onChange={this.onChange}
                                    value={this.state.password2}
                                    error={errors.password2}
                                    id="password2"
                                    type="password"
                                    className={classnames("", {
                                        invalid: errors.password2
                                    })}
                                />
                                <label htmlFor="password2">Confirm Password</label>
                                <span className="red-text">{errors.password2}</span>
                            </div>
                            <div className="input-field col s12">
                                <InputLabel id="role_label">Role</InputLabel>
                                <Select
                                    labelId="role_label"
                                    SelectDisplayProps={{ style: { paddingTop: 2, paddingBottom: 2, minHeight: "20px" } }}
                                    id="role" size='small' name="role" style={{ height: "150px", overflow: "scroll" }} options={[{ label: "Guest", value: "Guest" }, { label: "Hero", value: "Hero" }, { label: "Backend", value: "Backend" }, { label: "Supplier", value: "Supplier" }]} onChange={(opt) => this.setRole(opt.value)} />

                                <span className="red-text">{errors.role}</span>
                            </div>
                            <div className="input-field col s12">
                                <form encType='multipart/form-data'>
                                    <input
                                        type="file"
                                        accept=".png, .jpg, .jpeg"
                                        name="photo"
                                        id="photo"
                                        onChange={this.handlePhoto}
                                    // enctype="multipart/form-data"
                                    />
                                    <span className="red-text">{errors.contact_number}</span>
                                </form>
                            </div>
                            <div className="col s12" style={{ paddingLeft: "11.250px" }}>
                                <Button variant="contained" color="primary"
                                    style={{
                                        width: "100%",
                                    }}
                                    type="submit"
                                    value="Sign up"
                                >
                                    Add User
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

Register.propTypes = {
    registerUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { registerUser }
)(withRouter(Register));
