import React, { useState, useEffect } from "react";
// import Select from "@material-ui/core/Select";
import axios from 'axios';
import isEmpty from 'lodash.isempty';

var usersArray = [];
var ArrayIDs = [];

export default function App() {

    const [rows, setData] = useState([]);

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const idn = params.get('id_number');
    console.log("params", idn)
    useEffect(() => {
        const GetData = async () => {
            const result = await axios.get("/api/record/orderDetails", { params: { invoice_number: idn } }).then((response) => { return (response.data.order); })
            console.log("resultttttttttt=>", result);
            if (typeof result !== 'undefined') {
                setData(result);
                ArrayIDs = await result.map(currentVal => (currentVal._id));
            }
        }
        GetData();
        console.log(rows);
    }, []);

    const [usersData, setUserData] = useState([]);
    useEffect(() => {
        const GetUserData = async () => {
            const result = await axios.get("/api/record/record", { params: { get: "get" } }).then((response) => { return (response.data.order); })//axios('http://localhost:51760/Api/Emp/employee');    
            setUserData(result);
            usersArray = result.reduce(function (accum, currentVal) {
                accum[currentVal._id] = currentVal.name;
                return accum;
            }, {});
            console.log(usersArray);
        }
        GetUserData();
        console.log(rows);
    }, []);

    return (
        <div style={{ textAlign: "center" }}>
            <h5 style={{ display: "block" }}>Order Details</h5>

            {rows.length > 0 && <ul style={{ display: "inline-block", width: "400px", textAlign: "left" }}>
                <li style={{ marginTop: "7px", display: "flex", alignItems: "center" }}><div style={{ width: "150px", fontWeight: "800", float: "left" }}>Tracking Number:</div><div>{rows[0].tracking_number}</div> </li>

                <li style={{ marginTop: "7px", display: "flex", alignItems: "center" }}><div style={{ width: "150px", fontWeight: "800", float: "left" }}>Invoice Number:</div><div>{rows[0].invoice_number}</div> </li>

                <li style={{ marginTop: "7px", display: "flex", alignItems: "center" }}><div style={{ width: "150px", fontWeight: "800", float: "left" }}>Contact Number:</div><div>{rows[0].contact_number}</div> </li>

                <li style={{ marginTop: "7px", display: "flex", alignItems: "center" }}><div style={{ width: "150px", fontWeight: "800", float: "left" }}>Contact Name:</div><div>{rows[0].contact_name}</div> </li>

                <li style={{ marginTop: "7px", display: "flex", alignItems: "center" }}><div style={{ width: "150px", fontWeight: "800", float: "left" }}>Pickup Location:</div><div>{rows[0].address}</div> </li>

                <li style={{ marginTop: "7px", display: "flex", alignItems: "center" }}><div style={{ width: "150px", fontWeight: "800", float: "left" }}>Dropoff Location:</div><div>{rows[0].dropoff_address}</div> </li>

                <li style={{ marginTop: "7px", display: "flex", alignItems: "center" }}><div style={{ width: "150px", fontWeight: "800", float: "left" }}>Status:</div><div>{rows[0].status}</div> </li>

                <li style={{ marginTop: "7px", display: "flex", alignItems: "center" }}><div style={{ width: "150px", fontWeight: "800", float: "left" }}>Assigned On:</div><div>{!isEmpty(rows[0].assigningDate) ? new Date(rows[0].assigningDate).toLocaleString() : ""}</div> </li>

                <li style={{ marginTop: "7px", display: "flex", alignItems: "center" }}><div style={{ width: "150px", fontWeight: "800", float: "left" }}>Delivery Date:</div><div>{!isEmpty(rows[0].assignedDate) ? new Date(rows[0].assignedDate).toLocaleString() : ""}</div> </li>

                <li style={{ marginTop: "7px", display: "flex", alignItems: "center" }}><div style={{ width: "150px", fontWeight: "800", float: "left" }}>Pickedup Date:</div><div>{!isEmpty(rows[0].pickedup_date) ? new Date(rows[0].pickedup_date).toLocaleString() : ""}</div> </li>

                <li style={{ marginTop: "7px", display: "flex", alignItems: "center" }}><div style={{ width: "150px", fontWeight: "800", float: "left" }}>Pickedup Image:</div><div>{!isEmpty(rows[0].pickedup_img) &&
                    <img style={{ width: "120px", maxHeight: "120px" }} src={'../uploads/' + rows[0].pickedup_img} onError={(e) => { e.target.onerror = null; e.target.src = '../images/test.png' }} alt={"Delivered image"} />}</div> </li>

                <li style={{ marginTop: "7px", display: "flex", alignItems: "center" }}><div style={{ width: "150px", fontWeight: "800", float: "left" }}>Delivered Date:</div><div>{!isEmpty(rows[0].delivered_date) ? new Date(rows[0].delivered_date).toLocaleString() : ""}</div> </li>

                <li style={{ marginTop: "7px", display: "flex", alignItems: "center" }}><div style={{ width: "150px", fontWeight: "800", float: "left" }}>Delivered Image:</div><div>{!isEmpty(rows[0].delivered_img) &&
                    <img style={{ width: "120px", maxHeight: "120px" }} src={'../uploads/' + rows[0].delivered_img} onError={(e) => { e.target.onerror = null; e.target.src = '../images/test.png' }} alt={"Delivered image"} />}</div> </li>

            </ul>}
        </div>

    );
}

//ReactDOM.render(<App />, document.getElementById("root"));
