import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";
import { Link, Redirect, useHistory, useParams } from "react-router-dom";
import { GET_ERRORS, SET_CURRENT_USER, USER_LOADING } from "./types";

// Register User
export const registerUser = (userData, history) => dispatch => {
  //  console.log("history.location.pathname", history.location.pathname);
  var path = "./login";
  if (history.location.pathname === "/addUser")
    path = "./";
  axios
    .post("/api/users/register", userData)
    .then(res => history.push(path))
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

// Login - get user token
export const loginUser = userData => dispatch => {
  axios
    .post("/api/users/login", userData)
    .then(res => {
      // Save to localStorage

      // Set token to localStorage
      // const { token } = res.data;

      var token = JSON.parse(localStorage.getItem('jwtToken')) || [];
      token.push(res.data.token);

      // console.log("==API=>/api/users/login======>", token);
      localStorage.setItem("jwtToken", JSON.stringify(token));
      // Set token to Auth header
      setAuthToken(res.data.token);
      // Decode token to get user data
      // console.log("==API=>/api/users/login stringify======>", JSON.stringify(token));
      const decoded = jwt_decode(res.data.token);
      // Set current user
      // console.log("==API=>/api/users/login decoded======>", decoded);

      dispatch(setCurrentUser(decoded));
      // history.push("./dashboard");
      if (token.length > 1)
        window.location.href = "/" + (token.length - 1) + "/dashboard";
    }).catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

// Set logged in user
export const setCurrentUser = decoded => {
  console.log("==API=>setcurrentuser======>", decoded);
  return {
    type: SET_CURRENT_USER,
    payload: decoded
  };
};

// User loading
export const setUserLoading = () => {
  return {
    type: USER_LOADING
  };
};

// Log user out
export const logoutUser = () => dispatch => {
  // Remove token from local storage
  localStorage.removeItem("jwtToken");
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to empty object {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
};


// change password
export const updatePassword = (userData, history) => dispatch => {
  //  console.log("history.location.pathname", history.location.pathname);
  var path = "./dashboard";
  // if (history.location.pathname === "/addUser")
  //   path = "/";
  axios
    .post("/api/users/updatePassword", userData)
    .then(res => history.push(path))
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

// reset password
export const forgotPassword = (userData, history) => dispatch => {
  //  console.log("history.location.pathname", history.location.pathname);
  var path = "./login";
  // if (history.location.pathname === "/addUser")
  //   path = "/";
  axios
    .post("/api/users/forgotPassword", userData)
    .then(res => setTimeout(() => { history.push(path) }, 3000))
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};
