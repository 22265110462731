import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loginUser } from "../../actions/authActions";
import classnames from "classnames";
import Button from '@material-ui/core/Button';

import axios from 'axios';
import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
// refresh token
import { refreshTokenSetup } from '../../utils/refreshToken';
import setAuthToken from "../../utils/setAuthToken";
import jwt_decode from "jwt-decode";
import { SET_CURRENT_USER } from "../../actions/types"; //GET_ERRORS, USER_LOADING
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebook } from "@fortawesome/free-brands-svg-icons"
import { ReactComponent as SVGIcon } from "../../images/google-light.svg";
require('dotenv').config({ path: "../../../../config.env" });
const clientId = "143880167187-l3mr84iqjehokn65peflg39np75o15vh.apps.googleusercontent.com";
// Set logged in user
export const setCurrentUser = decoded => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded
  };
};

var pathname = '';

class Login extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      errors: {}
    };
  }

  componentDidMount() {
    // If logged in and user navigates to Login page, should redirect them to dashboard
    var path_arr = (window.location.pathname).split("/");
    var index = path_arr[1];
    if (localStorage.jwtToken) {
      const token = JSON.parse(localStorage.jwtToken);
      // const decoded = jwt_decode(token[index]);
      if (this.props.auth.isAuthenticated && index < token.length) {
        const { user } = this.props.auth;
        if (user.role === "Guest") {
          this.props.history.push("/" + index + "/dashboard");
        } else if (user.role === "Hero") {
          this.props.history.push("/" + index + "/myOrders");
        } else {
          this.props.history.push("/" + index + "/forms");
        }
      }
    } else {
      if (index > 0)
        window.location.href = "/0/login"
    }

  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors
      });
    }
  }

  onChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();

    const userData = {
      email: this.state.email,
      password: this.state.password
    };

    this.props.loginUser(userData);
  };

  onSuccess = (ress) => {
    console.log('Login Success: currentUser:', ress);
    // alert("ok")
    const newperson = {
      id: ress.profileObj.googleId,
      name: ress.profileObj.name,
      username: ress.profileObj.googleId,
      email: ress.profileObj.email,
      profile_pic: ress.profileObj.imageUrl,
      role: "Guest",
      date: Date.now(),
    };
    axios
      .post("/api/record/record/addu", newperson)
      .then((res) => {
        console.log('Login Success: res.data:', res.data);
        const token = JSON.parse(localStorage.getItem('jwtToken')) || [];
        token.push(res.data.token);
        // const token = res.data;
        localStorage.setItem("jwtToken", JSON.stringify(token));
        // Set token to Auth header
        setAuthToken(res.data.token);
        // Decode token to get user data
        const decoded = jwt_decode(res.data.token);
        // Set current user
        setCurrentUser(decoded);
        // console.log("Added Obj->: " + res.data);
        // if (res.data.role !== 'Admin') {
        //   pathname = 'forms';
        // }
        refreshTokenSetup(ress);
        ress.disconnect();
        if (decoded.role === 'Guest') {
          pathname = 'dashboard';
        } else if (decoded.role === 'Hero') {
          pathname = 'myOrders';
        } else {
          pathname = 'forms';
        }
        window.location.href = "/" + (token.length - 1) + "/" + pathname;

        //following is not good idea
        // this.props.history.push("/" + pathname);
      });

  };

  responseFacebook = (ress) => {
    //console.log(ress);
    // return true;

    const newperson = {
      id: ress.userID,
      name: ress.name,
      username: ress.userID,
      email: ress.email,
      role: "Guest",
      date: Date.now(),
      profile_pic: ress.picture.data.url,
    };//ress.signedRequest,
    var pathname = '';
    axios
      .post("/api/record/record/addu", newperson)
      .then((res) => {
        const token = JSON.parse(localStorage.getItem('jwtToken')) || [];
        token.push(res.data.token);
        // const token = res.data;
        localStorage.setItem("jwtToken", JSON.stringify(token));
        // Set token to Auth header
        setAuthToken(res.data.token);
        // Decode token to get user data
        const decoded = jwt_decode(res.data.token);
        // Set current user
        setCurrentUser(decoded);
        // console.log("Added Obj->: " + res.data);
        if (decoded.role === 'Guest') {
          pathname = 'dashboard';
        } else if (decoded.role === 'Hero') {
          pathname = 'myOrders';
        } else {
          pathname = 'forms';
        }
        window.location.href = "./" + pathname;
      });

    // refreshTokenSetup(res);
  }

  onFailure = (res) => {
    console.log('Login failed: res:', res);

  };

  render() {
    const { errors } = this.state;

    return (
      <div className="container">
        <div className="row">
          <div className="col s8 offset-s2">

            <div className="col s12" style={{ paddingLeft: "11.250px" }}>

              <h4>
                <b>Login</b> with Email and Password
              </h4>
              <p className="grey-text text-darken-1">
                Don't have an account? <Link to="./register">Register</Link>
                <Link style={{ float: "right" }} to="./forgotPassword">Forgot Password?</Link>
              </p>
            </div>
            <form noValidate onSubmit={this.onSubmit} style={{ textAlign: "center" }}>
              <div className="input-field col s12">
                <input
                  onChange={this.onChange}
                  value={this.state.email}
                  error={errors.email}
                  id="email"
                  type="email"
                  className={classnames("", {
                    invalid: errors.email || errors.emailnotfound
                  })}
                />
                <label htmlFor="email">Email</label>
                <span className="red-text">
                  {errors.email}
                  {errors.emailnotfound}
                </span>
              </div>
              <div className="input-field col s12">
                <input
                  onChange={this.onChange}
                  value={this.state.password}
                  error={errors.password}
                  id="password"
                  type="password"
                  className={classnames("", {
                    invalid: errors.password || errors.passwordincorrect
                  })}
                />
                <label htmlFor="password">Password</label>
                <span className="red-text">
                  {errors.password}
                  {errors.passwordincorrect}
                </span>
              </div>
              <div className="col s12" style={{ paddingLeft: "11.250px", textAlign: "center" }}>
                <Button variant="contained" color="primary"
                  style={{
                    width: "100%",
                  }}
                  type="submit"
                  value="Login"
                >
                  Login
                </Button>

              </div>
              <h5 style={{ visibility: "hidden" }}>OR</h5>
              <hr />
              <h7>OR </h7>
              <hr />

              <GoogleLogin
                clientId={clientId}
                onSuccess={this.onSuccess}
                onFailure={this.onFailure}
                cookiePolicy={'single_host_origin'}
                isSignedIn={true}
                autoLoad={false}
                icon={<i class="fab fa-google-plus-g"></i>}
                render={renderProps => (
                  <Button onClick={renderProps.onClick} style={{ height: "37px" }}
                    class="MuiButtonBase-root MuiButton-root MuiButton-outlined MuiButton-outlinedPrimary"
                  >{<SVGIcon style={{ width: "25px" }} />}</Button>
                )}
                //className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary"
                buttonText=""
              />&nbsp; &nbsp;
              <FacebookLogin
                appId="890446928210353"
                autoLoad={false}
                fields="name,email,picture"
                callback={this.responseFacebook}
                cssClass="MuiButtonBase-root MuiButton-root MuiButton-outlined MuiButton-outlinedPrimary"
                icon={<FontAwesomeIcon style={{ width: "30px", height: "25px" }} icon={faFacebook} />}
                textButton=""
              // size="metro"
              >

              </FacebookLogin>

              {/* <FacebookLogin
                appId="890446928210353"
                autoLoad={false}
                callback={this.responseFacebook} /> */}
            </form>
          </div>

        </div>
      </div>
    );
  }
}

Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { loginUser }
)(Login);
