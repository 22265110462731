import React, { useState, useEffect, useRef } from "react";

import MUIDataTable from './outer/src/';

import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import isEmpty from 'lodash.isempty';
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
// import Select from "@material-ui/core/Select";
import axios from 'axios';
// import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';

import MenuIcon2 from "@material-ui/icons/Menu";
import { StyledMenu } from "./StyledMenu";
// import Select from 'react-select';

import { IconButton } from "@material-ui/core";
// import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import MenuIcon from "@material-ui/icons/Message";
import TextField from '@material-ui/core/TextField';
//import "../App.css";
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import * as turf from '@turf/turf';
import DirectionsBusFilledIcon from '@mui/icons-material/DirectionsBusFilled';
import DoneAll from "@mui/icons-material/DoneAll";
import Chip from '@mui/material/Chip';

mapboxgl.accessToken = 'pk.eyJ1IjoibWluYXdheWFwcCIsImEiOiJja3M4enByajUwb3pyMm9ub2NkNXZxcXlzIn0.8f4lJHK2YIi5R-ogkG1huQ';
var truckLocation = [-83.093, 42.376];
var pointHopper = {};
var warehouse = turf.featureCollection([turf.point(truckLocation)]);
// Create an empty GeoJSON feature collection for drop off locations
var dropoffs = turf.featureCollection([]);
// Create an empty GeoJSON feature collection, which will be used as the data source for the route before users add any new data
var nothing = turf.featureCollection([]);
var routeGeoJSON;

var ArrayPlaces = [];
var prev_opened_grp = "";
var prev_opened_obj = "";
var tempArray = [];
var option_value = "";
var usersArray = [];
// var order_id = "";

const App = ({ component: Component, auth }) => {
  const [responsive, setResponsive] = useState("vertical");
  const [tableBodyHeight, setTableBodyHeight] = useState("");
  const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorIndex, setAnchorIndex] = useState("");
  const [anchorElement, setAnchorElement] = useState([]);

  const [activeMarker, setActiveMarker] = useState({});
  const [selectedPlace, setSelectedPlace] = useState({});
  const [showingInfoWindow, setShowingInfoWindow] = useState(false);
  const [places, setPlaces] = useState([]);
  const myinputref = useRef(null);
  const [checkboxcheck, setcheckboxcheck] = useState(true);
  const [picIndex, setpicIndex] = useState(0);
  const [picStringPicked, setpicStringPicked] = useState('');
  const [picStringDelivered, setpicStringDelivered] = useState('');

  const { user } = auth;
  //console.log(user);
  const handleClickMenu = (e) => {
    // alert(e.currentTarget)
    setAnchorEl(e.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    tempArray[anchorIndex] = false;
    setAnchorElement(tempArray);
    tempArray = [];
    document.getElementById("text_" + anchorIndex).style.display = 'none';
  };
  useEffect(() => {
    const GetUserData = async () => {
      const result = await axios.get("/api/record/record", { params: { get: "get" } }).then((response) => { return (response.data.order); })//axios('http://localhost:51760/Api/Emp/employee');    
      // setUserData(result);
      usersArray = result.reduce(function (accum, currentVal) {
        accum[currentVal._id] = currentVal.name;
        return accum;
      }, {});
      console.log(usersArray);
    }
    GetUserData();
    // console.log("rows=======", rows);
  }, []);
  const [rows, setData] = useState([]);
  useEffect(() => {
    const GetData = async () => {
      const result = await axios.get("/api/record/forms", { params: { id: user.id, assignedDate: "" } }).then((response) => { return (response.data.order); })//axios('http://localhost:51760/Api/Emp/employee');    

      //lat, lng, address are single for a grp,,,,, dropoff_lat... are individual for order so are many in a grp
      ArrayPlaces = result.map(currentVal => (
        {
          "dropoff_lat": currentVal.lat, "dropoff_lng": currentVal.lng, "dropoff_address": currentVal.address,
          "lat": currentVal.dropoff_lat, "lng": currentVal.dropoff_lng,
          "address": currentVal.dropoff_address, "assigned_order": currentVal.assigned_order
        }
      ));
      document.getElementById("orderDeliveryDate").value = "";
      // setPlaces(ArrayPlaces);
      // showOrder();
      setData(result);
    }
    GetData();

    console.log(rows);
  }, []);

  const addNew = (id_number) => {
    document.getElementById("text_" + id_number).style.display = 'inline';
  }

  const submitNewComment = (id_number) => {
    // document.getElementById("text_" + id_number).style.display = 'inline';
    const newData = {
      _id: id_number,
      text: document.getElementById("mytext_" + id_number).value,
      uid: user.id
    };
    axios.post("/api/record/record/addcomment", newData)
      .then((res) => setData(res.data.order));
    handleCloseMenu();
  }

  const columns = [{ name: "_id", label: "id", options: { display: "excluded" } },
  { name: "tracking_number", label: "Tracking No." },
  { name: "invoice_number", label: "Invoice No." },
  {
    name: "address", label: "Pickup Location", options: {
      display: "excluded",
      customBodyRender: (value, tableMeta, updateValue) => {
        return (value + ' --- Date:' + (new Date(tableMeta.rowData[6])).toLocaleString());
      }
    }
  },
  // { name: "address", label: "Pickup Location" }, 
  { name: "dropoff_address", label: "Location" },
  {
    name: "assigningDate", label: "Assigned On", options: {
      customBodyRender: (value, tableMeta, updateValue) => {
        return ((new Date(value)).toLocaleString())
      }
    }
  },
  {
    name: "assignedDate", label: "Sprint Date", options: {
      // display: "excluded",
      customBodyRender: (value, tableMeta, updateValue) => {
        return ((new Date(value)).toLocaleString())
      }
    }
  },
  { name: "pickedup_img", label: "pickedup_img", options: { display: "excluded" } },
  { name: "delivered_img", label: "delivered_img", options: { display: "excluded" } },
  { name: "contact_name", label: "Contact Name" },
  {
    name: "status", label: "Status", options: {
      customBodyRender: (value, tableMeta, updateValue) => {
        return (
          // <span id={`${tableMeta.rowData[0]}`} >{value}</span>
          <Select id="react_select_id" style={{ width: "120px" }} onChange={(opt) => assignorder(opt.target.value, `${tableMeta.rowData[0]}`)}
            labelId="demo-simple-select-label"
            value={tableMeta.rowData[10]}
          // label="Select Status"
          // displayEmpty
          >
            <MenuItem disabled value="Assigned">Assigned</MenuItem>
            <MenuItem value="Picked">Picked up</MenuItem>
            <MenuItem value="Delivered">Delivered</MenuItem>
          </Select>
        );
      }
    }
  },
  { name: "pickedup_date", label: "pickedup_date", options: { display: "excluded" } },
  { name: "pickedup_date", label: "pickedup_date", options: { display: "excluded" } },
  { name: "comments", label: "comments", options: { display: "excluded" } },
  {
    name: "assignedto",
    label: "Actions",
    options: {
      viewColumns: false,
      filter: false,
      customBodyRender: (value, tableMeta, updateValue) => {
        return (
          <div style={{ display: "flex" }}>

            <img style={{ cursor: "pointer", marginLeft: "10px", width: "30px", height: "30px" }} onClick={(opt) => assignorder("Picked", `${tableMeta.rowData[0]}`)} src={require('../images/picked.png').default} alt={"Pickup image"} title="Picked up" />

            <img style={{ cursor: "pointer", marginLeft: "10px", width: "30px", height: "30px" }} onClick={(opt) => assignorder("Delivered", `${tableMeta.rowData[0]}`)} src={require('../images/delivered.png').default} alt={"Pickup image"} title="Delivered" />

            {rows.length > 0 && <StyledMenu
              id={"com_" + `${tableMeta.rowData[0]}`}
              key={"com_" + tableMeta.rowData[0]}
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorElement["com_" + `${tableMeta.rowData[0]}`])}
              onClose={handleCloseMenu}
            >
              <div style={{ width: "300px", minHeight: "180px", display: "block", padding: "10px" }}>
                <h5>&#8288;Comments</h5>
                {tableMeta.rowData[13].length > 0 ? tableMeta.rowData[13].map((cmnt, indx) => {
                  if (!isEmpty(cmnt))
                    return (
                      <div style={{ display: 'list-item' }}><b>({usersArray[cmnt.creator_id]}): </b>{cmnt.comment_text}</div>
                    );
                }) : 'No Comments'}
                <div id={"text_" + tableMeta.rowData[0]} s style={{ display: 'none' }}>
                  <h6>Add Comment</h6>
                  <input type='text' id={"mytext_" + tableMeta.rowData[0]} name="sms_number2" />
                  <Button id="done_assigning" variant="contained" color="primary"
                    onClick={(e) => { submitNewComment(tableMeta.rowData[0]) }}>Submit</Button>
                  <br />
                </div>
                <br />
                <Button id="done_assigning" variant="contained" color="primary"
                  onClick={(e) => { addNew(tableMeta.rowData[0]) }}>Add New Comment</Button>

              </div>
            </StyledMenu>}
            <a onClick={(e) => { //Comments clicked

              setAnchorEl(e.currentTarget)
              setAnchorIndex(`${tableMeta.rowData[0]}`);
              tempArray["com_" + `${tableMeta.rowData[0]}`] = true;
              setAnchorElement(tempArray);
              tempArray = [];

            }}>Comments</a>
          </div>

        );
      }
    }
  }];

  const options = {
    filter: true,
    filterType: "dropdown",
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    selectableRows: 'single',
    selectableRowsHideCheckboxes: true,
    searchOpen: true,
    textLabels: {
      body: {
        noMatch: 'Loading...',
      },
    },
    draggableColumns: {
      enabled: true,
    },
    onTableChange: (action, state) => {
      console.log(action);
      //console.dir(state);
    },
    onGroupExpansionChange: (group, expanded) => {
      console.dir(group);
      console.dir(expanded);
      changeGroup(group, expanded);
    },
    grouping: {
      columnIndexes: [3],
      // expanded: {
      //   "Business Consultant": {
      //     open: true
      //   }
      // }
    },
    expandableRows: true,
    expandableRowsHeader: false,
    expandableRowsOnClick: true,
    renderExpandableRow: (rowData, rowMeta) => {
      const colSpan = rowData.length + 1;
      setpicStringPicked(rowData[7]);
      setpicStringDelivered(rowData[8]);
      const Pickedimg_array = rowData[7].split('  ');
      const Deliveredimg_array = rowData[8].split('  ');
      return (
        <TableRow >
          <TableCell style={{ paddingLeft: "10%" }} colSpan={colSpan}>
            {/* <div style={{ width: "300px", minHeight: "180px", display: "block", padding: "10px" }}> */}
            <h6><b>Order History</b></h6>
            <div style={{ display: 'flex', alignItems: "center" }}>
              <h7 style={{ paddingLeft: "20px" }}><b>Pickedup On: </b>{!isEmpty(rowData[11]) ? new Date(rowData[11]).toLocaleString() : ""}</h7>
              {Pickedimg_array.map((imgs, indx) => {
                if (imgs !== '')
                  return (
                    <div>
                      <img style={{ width: "100px", maxHeight: "100px" }} src={'../uploads/' + imgs} alt={"Pickup image"} /><a onClick={(opt) => uploadPic(indx, "Picked", rowData[0])} style={{ marginLeft: "-80px", cursor: "pointer", backgroundColor: "white" }}>Change Image</a>
                      {(Pickedimg_array.length == indx + 1 && Pickedimg_array.length < 5) && <a onClick={(opt) => uploadPic(Pickedimg_array.length, "Picked", rowData[0])} style={{ marginLeft: "15px", cursor: "pointer" }}>Add Image</a>}</div>
                  );
              })}
              < h7 style={{ paddingLeft: "20px" }}> <b>Delivered On: </b>{!isEmpty(rowData[12]) ? new Date(rowData[12]).toLocaleString() : ""}</h7>
              {Deliveredimg_array.map((imgs, indx) => {
                if (imgs !== '')
                  return (
                    <div>
                      <img style={{ width: "100px", maxHeight: "100px" }} src={'../uploads/' + imgs} onError={(e) => { e.target.onerror = null; e.target.src = '../images/test.png' }} alt={"Pickup image"} /><a onClick={(opt) => uploadPic(indx, "Delivered", rowData[0])} style={{ marginLeft: "-80px", cursor: "pointer", backgroundColor: "white" }}>Change Image</a>
                      {(Deliveredimg_array.length == indx + 1 && Deliveredimg_array.length < 5) && <a onClick={(opt) => uploadPic(Deliveredimg_array.length, "Delivered", rowData[0])} style={{ marginLeft: "15px", cursor: "pointer" }}>Add Image</a>}</div>
                  );
              })}

              {/* <Select id="react_select_id" style={{ height: "200px", overflow: "scroll" }} options={aquaticCreatures} onChange={(opt) => assignorder(opt.value, anchorIndex)} /> */}
              {/* </div> */}
            </div>
          </TableCell>
        </TableRow>
      );
    },
    onRowExpansionChange: (curExpanded, allExpanded, rowsExpanded) => console.log(curExpanded, allExpanded, rowsExpanded)
  };

  const sendSMS = (id_number) => {
    // alert(document.getElementById("sms_number" + id_number).value)
    const result = axios.get("/api/record/sendMessage", { params: { "id_number": id_number, "mobile_number": document.getElementById("sms_number" + id_number).value } }).then((response) => { return (response.data.order); })
    document.getElementById("sms_number" + id_number).value = "";
    setAnchorEl(null);
    tempArray[anchorIndex] = false;
    setAnchorElement(tempArray);
    tempArray = [];
  }

  const uploadPic = (index, pic_type, order_id) => {
    setAnchorIndex(order_id);
    setpicIndex(index);
    option_value = pic_type;
    myinputref.current.click();
    return false;
  };


  const assignorder = (optvalue, order_id) => {
    setAnchorIndex(order_id);
    option_value = optvalue;
    // console.log("Order_id", order_id)
    // alert(order_id);
    myinputref.current.click();
    return false;
  }

  useEffect(() => {
    showOrder()
  }, [places]);

  const changeGroup = (group, expanded) => {
    console.log("expanded===>", group)
    if (prev_opened_grp !== "" && expanded[group].open)
      expanded[prev_opened_grp].open = false;
    else if (!expanded[group].open) {
      document.getElementById("mapBoxDiv").innerHTML = "";
      prev_opened_grp = "";
      prev_opened_obj = "";
      return true;
    }

    const placesNew = ArrayPlaces.filter(function (el) {
      return el.dropoff_address === group[0].split(' --- Date:')[0];
    });
    //console.log("placesNew===>",placesNew)
    setPlaces(placesNew);
    prev_opened_grp = group;
    prev_opened_obj = expanded;
  }

  const getMyOrder = (var_type) => {
    // alert("inn")
    // useEffect(() => {
    if (var_type === "check" && document.getElementById("checkall").checked) {
      setcheckboxcheck(true);
      document.getElementById("orderDeliveryDate").value = "";
    }
    else {
      setcheckboxcheck(false);
    }

    document.getElementById("mapBoxDiv").innerHTML = "";
    if (prev_opened_grp !== "")
      prev_opened_obj[prev_opened_grp].open = false;
    prev_opened_grp = "";
    prev_opened_obj = "";
    const GetData = async () => {
      const result = await axios.get("/api/record/forms", { params: { id: user.id, assignedDate: document.getElementById("orderDeliveryDate").value } }).then((response) => { return (response.data.order); })//axios('http://localhost:51760/Api/Emp/employee');    
      setData(result);
      ArrayPlaces = await result.map(currentVal => (
        {
          "dropoff_lat": currentVal.lat, "dropoff_lng": currentVal.lng, "dropoff_address": currentVal.address,
          "lat": currentVal.dropoff_lat, "lng": currentVal.dropoff_lng,
          "address": currentVal.dropoff_address
        }
      ));
      // useEffect(()=>{})
      // setPlaces(ArrayPlaces);

    }
    GetData();
    console.log(rows);
    // }, []);
  }

  const showOrder = () => {
    // console.log("places=>", places)
    if (places.length > 0) {
      truckLocation = [places[0].dropoff_lng, places[0].dropoff_lat];
      warehouse = turf.featureCollection([turf.point(truckLocation)]);
      // document.getElementById("mapPointsDiv").style.display = "none";
      var map = new mapboxgl.Map({
        container: "mapBoxDiv",
        style: 'mapbox://styles/mapbox/streets-v11',
        center: truckLocation,
        zoom: 12
      });

      map.on('load', function () {
        var marker = document.createElement('div');
        marker.classList = 'truck';

        // Create a new marker
        var truckMarker = new mapboxgl.Marker(marker)
          .setLngLat(truckLocation)
          .setPopup(
            new mapboxgl.Popup({ offset: 25 }) // add popups
              .setHTML(
                '<h7>' +
                "Starting: " +
                '</h7>'
              )
          )
          .addTo(map);
        setTimeout(() => {
          // Create a circle layer
          map.addLayer({
            id: 'warehouse',
            type: 'circle',
            source: {
              data: warehouse,
              type: 'geojson'
            },
            paint: {
              'circle-radius': 20,
              'circle-color': 'white',
              'circle-stroke-color': '#3887be',
              'circle-stroke-width': 3
            }
          });

          // Create a symbol layer on top of circle layer
          map.addLayer({
            id: 'warehouse-symbol',
            type: 'symbol',
            source: {
              data: warehouse,
              type: 'geojson'
            },
            layout: {
              'icon-image': 'grocery-15',
              'icon-size': 1
            },
            paint: {
              'text-color': '#3887be'
            }
          });

          map.addLayer(
            {
              id: 'routeline-active',
              type: 'line',
              source: 'route',
              layout: {
                'line-join': 'round',
                'line-cap': 'round'
              },
              paint: {
                'line-color': '#3887be',
                'line-width': ['interpolate', ['linear'], ['zoom'], 12, 3, 22, 12]
              }
            },
            'waterway-label'
          );

          map.addLayer(
            {
              id: 'routearrows',
              type: 'symbol',
              source: 'route',
              layout: {
                'symbol-placement': 'line',
                'text-field': '▶',
                'text-size': [
                  'interpolate',
                  ['linear'],
                  ['zoom'], 12, 24, 22, 60
                ],
                'symbol-spacing': [
                  'interpolate',
                  ['linear'],
                  ['zoom'], 12, 30, 22, 160
                ],
                'text-keep-upright': false
              },
              paint: {
                'text-color': '#3887be',
                'text-halo-color': 'hsl(55, 11%, 96%)',
                'text-halo-width': 3
              }
            },
            'waterway-label'
          );
        }, 2000);
      });
      //implementation of newDropoff
      const coordss = places.map(function (elem) {
        var pt = turf.point([elem.lng, elem.lat], {
          orderTime: Date.now(),
          key: Math.random()
        });
        dropoffs.features.push(pt);
        pointHopper[pt.properties.key] = pt;
        var marker = document.createElement('div');
        marker.style.color = "red";
        marker.style.border = "1px solid blue";
        marker.style.padding = "5px"
        marker.style.borderRadius = "10px"
        marker.style.background = "white";
        marker.innerHTML = elem.assigned_order;
        new mapboxgl.Marker(marker)
          .setLngLat([elem.lng, elem.lat])
          .setPopup(
            new mapboxgl.Popup({ offset: 25 }) // add popups
              .setHTML(
                '<h7>' + "<b>(No." + elem.assigned_order + ")</b> " + elem.address +
                '</h7>'
              )
          )

          .addTo(map);
        return elem.lng + "," + elem.lat;
      }).join(";");

      var aaa = axios.get('https://api.mapbox.com/optimized-trips/v1/mapbox/driving/' +
        places[0].dropoff_lng + ',' + places[0].dropoff_lat + ';' + coordss, {
        params: {
          steps: "true", geometries: "geojson",
          source: "first", access_token: mapboxgl.accessToken
        }
      }).then(function (data) {
        console.log("dattaaaaa=>", data.data)
        // Create a GeoJSON feature collection
	if (typeof data.data.trips[0].geometry !== 'undefined')
        routeGeoJSON = turf.featureCollection([
          turf.feature(data.data.trips[0].geometry)
        ]);
	else
          routeGeoJSON = nothing;
  
        console.log("dropoffs===>", dropoffs);
        console.log("routeGeoJSON====>", routeGeoJSON)
        map.addLayer({
          id: 'dropoffs-symbol',
          type: 'symbol',
          source: {
            data: dropoffs,
            type: 'geojson'
          },
          layout: {
            'icon-allow-overlap': true,
            'icon-ignore-placement': true,
            'icon-image': 'marker-15'
          }
        });

        map.addSource('route', {
          type: 'geojson',
          data: routeGeoJSON
        });
        //

      });
    }
  }

  const dataURIToBlob = (dataURI) => {
    const splitDataURI = dataURI.split(",");
    const byteString =
      splitDataURI[0].indexOf("base64") >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(":")[1].split(";")[0];
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);
    return new Blob([ia], { type: mimeString });
  };

  const handlePhoto = e => {
    if (e.target.files[0]) {

      const file = e.target.files[0];
      var reader = new FileReader();
      reader.onload = (e) => {
        var img = document.createElement("img");
        img.onload = () => {
          var canvas = document.createElement('canvas');
          var ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0);

          var MAX_WIDTH = 300;
          var MAX_HEIGHT = 300;
          var width = img.width;
          var height = img.height;
          // alert(width);
          if (width > height) {
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width;
              width = MAX_WIDTH;
            }
          } else {
            if (height > MAX_HEIGHT) {
              width *= MAX_HEIGHT / height;
              height = MAX_HEIGHT;
            }
          }
          canvas.width = width;
          canvas.height = height;
          var ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, width, height);
          var dataurl = canvas.toDataURL("image/png");
          const data = new FormData();
          data.append("avatar", dataURIToBlob(dataurl)); // <-- use "avatar" instead of "file" here
          data.append("idd", anchorIndex);
          data.append("status", option_value);
          data.append("uid", user.id);
          if (picStringPicked && option_value == 'Picked') {
            data.append("oldString", picStringPicked);
            data.append("picIndex", picIndex);
          }
          if (picStringDelivered && option_value == 'Delivered') {
            data.append("oldString", picStringDelivered);
            data.append("picIndex", picIndex);
          }
          if (document.getElementById("checkall").checked)
            data.append("assignedDate", "");
          else
            data.append("assignedDate", document.getElementById("orderDeliveryDate").value);
          axios({
            method: 'post',
            url: '/api/record/upload',
            data: data,
            config: { headers: { 'Content-Type': 'multipart/form-data' } }
          }).then((res) => {
            setTimeout(() => {
              setData(res.data.order)
            }, 5000);
            setAnchorEl(null);
            document.getElementById("photo").value = "";

          });
        }
        img.src = e.target.result;

      }
      reader.readAsDataURL(file);





    }
  };


  return (
    <div className="App">
      <div style={{ display: "none" }}>
        <form encType='multipart/form-data'>
          <input
            type="file"
            accept=".png, .jpg, .jpeg"
            name="photo"
            id="photo"
            onChange={handlePhoto}
            ref={myinputref}
          // enctype="multipart/form-data"
          />
        </form>
      </div>
      <h5 style={{ display: "block", float: "left", marginRight: "20px" }}> My Orders</h5>
      <div style={{ marginLeft: "20px" }}>
        <TextField
          id="orderDeliveryDate"
          label="Orders Dated"
          type="date"
          onChange={(e) => {
            document.getElementById("checkall").checked = false;
            getMyOrder("date");
          }}
          defaultValue={new Date().toISOString().split('T')[0]}
          // className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <span style={{ paddingLeft: "20px" }}>Show All <Checkbox
          onChange={(e) => { getMyOrder("check") }}
          name="checkedB"
          color="primary"
          id="checkall"
          checked={checkboxcheck}
        /></span>
      </div>

      <div>
        {/* <React.Fragment>  */}
        <MUIDataTable
          title={""}
          data={rows}
          columns={columns}
          options={options}
        />
        {/* </React.Fragment> */}
      </div>
      <div id="mapBoxDiv" style={{ width: "80vw", height: "400px" }}></div>

    </div>
  );
}

App.propTypes = {
  auth: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool
};

const mapStateToProps = state => ({
  auth: state.auth,
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps)(App);

//ReactDOM.render(<App />, document.getElementById("root"));
