import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import Button from '@material-ui/core/Button';
import { Link } from "react-router-dom";
import axios from 'axios';

class Dashboard extends Component {
  constructor() {
    super();
    // const { user } = this.props.auth;
    this.state = {
      user: { name: "" },
    };
  }
  onLogoutClick = e => {
    e.preventDefault();
    this.props.logoutUser();
    window.location.href = "./login";
  };
  componentDidMount() {

    const { user } = this.props.auth;

    const newUser = {
      id: user.id
    };
    var result = axios
      .post("/api/record/record/updateProfile", newUser)
      .then((response) => { (this.setState({ user: response.data.order[0] })) }
      );
    // this.setState({
    //     name: result.name
    // });
  }

  render() {
    const { user } = this.props.auth;
    // console.log("dashboard user==>", user);
    var profilePictureLink = this.state.user.profile_pic;
    if (this.state.user.profile_pic) {
      if (!this.state.user.profile_pic.includes('http')) {
        profilePictureLink = '../../uploads/' + this.state.user.profile_pic;
      }
    }
    console.log("profilePictureLink dashboard===>", profilePictureLink)
    // return(<div style={{ height: "75vh" }} className="container valign-wrapper"></div>);
    return (
      <div style={{ height: "75vh" }} className="container valign-wrapper">
        <div className="row">
          <div className="landing-copy col s12 center-align">
            {profilePictureLink !== "" && <img style={{ maxWidth: "150px" }} src={profilePictureLink} onError={(e) => { e.target.onerror = null; e.target.src = '../../images/test.png' }} alt="Profile Picture" />}
            <h4>
              Mr. {this.state.user.name.split(" ")[0]}
              <p className="flow-text grey-text text-darken-1">
                Welcome to {" "}
                <span style={{ fontFamily: "monospace" }}>MinAway-App</span>{" as " + user.role}
              </p>
              {user.role === 'Guest' &&
                <p className="flow-text grey-text text-darken-1">
                  Contact admin for new role of your interest.
                </p>
              }

            </h4>
            <h6>
              <Link to="./updateProfile">
                <i className="material-icons left"></i>Edit Profile
              </Link><br /><br />
              {this.state.user.username === 'Email' && <Link to="./updatePassword">
                <i className="material-icons left"></i>Change Password
              </Link>}
            </h6><br />
            <Button
              variant="contained" color="primary"
              onClick={this.onLogoutClick}
            >
              Logout
            </Button>
          </div>
        </div>
      </div>
    );

  }
}

Dashboard.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { logoutUser }
)(Dashboard);
