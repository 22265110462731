import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { forgotPassword } from "../actions/authActions";
import classnames from "classnames";
import Button from '@material-ui/core/Button';
const Validator = require("validator");

var pathname = '';

class Register extends Component {
    constructor() {
        super();
        this.state = {
            email: "",
            errors: {}
        };
    }

    componentDidMount() {

    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
    }

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    onSubmit = e => {
        const { user } = this.props.auth;
        e.preventDefault();
        if (!Validator.isEmail(this.state.email)) {
            alert("Email is invalid");
            return false;
        }

        const newUser = {
            email: this.state.email,
        };
        document.getElementById('msg').style.display = 'initial';
        this.props.forgotPassword(newUser, this.props.history);
    };

    render() {
        const { errors } = this.state;

        return (
            <div className="container">
                <div className="row">
                    <div className="col s8 offset-s2">
                        <Link to="./login" className="btn-flat waves-effect">
                            <i className="material-icons left">keyboard_backspace</i> Back to
                            Login
                        </Link>
                        <div className="col s12" style={{ paddingLeft: "11.250px" }}>
                            <h4>
                                Reset Password
                            </h4>
                        </div>
                        <form noValidate onSubmit={this.onSubmit}>

                            <div className="input-field col s12">
                                <input
                                    onChange={this.onChange}
                                    value={this.state.email}
                                    error={errors.email}
                                    id="email"
                                    type="text"
                                    className={classnames("", {
                                        invalid: errors.email || errors.emailnotfound
                                    })}
                                />
                                <label htmlFor="email">Email</label>
                                <span className="red-text">{errors.email}{errors.emailnotfound}</span>
                                <p id="msg" style={{ display: "none" }}>New password has been sent to your email...</p>
                            </div>

                            <div className="col s12" style={{ paddingLeft: "11.250px" }}>
                                <Button variant="contained" color="primary"
                                    style={{
                                        width: "100%",
                                    }}
                                    type="submit"
                                    value="Sign up"
                                >
                                    Reset Password
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

Register.propTypes = {
    forgotPassword: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { forgotPassword }
)(withRouter(Register));
