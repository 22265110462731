import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updatePassword } from "../actions/authActions";
import classnames from "classnames";
import Button from '@material-ui/core/Button';
const Validator = require("validator");

var pathname = '';

class Register extends Component {
    constructor() {
        super();
        this.state = {
            current_password: "",
            password: "",
            password2: "",
            errors: {}
        };
    }

    componentDidMount() {

    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
    }

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    onSubmit = e => {
        const { user } = this.props.auth;
        e.preventDefault();
        if (!this.state.current_password) {
            alert("Current password is required");
            return false;
        }
        if (!Validator.isLength(this.state.password, { min: 6, max: 30 })) {
            alert("Password must be at least 6 characters");
            return false;
        }

        if (!Validator.equals(this.state.password, this.state.password2)) {
            alert("Passwords must match");
            return false;
        }

        const newUser = {
            current_password: this.state.current_password,
            password: this.state.password,
            id: user.id
        };

        this.props.updatePassword(newUser, this.props.history);
    };

    render() {
        const { errors } = this.state;

        return (
            <div className="container">
                <div className="row">
                    <div className="col s8 offset-s2">
                        <Link to="./dashboard" className="btn-flat waves-effect">
                            <i className="material-icons left">keyboard_backspace</i> Back to
                            Profile
                        </Link>
                        <div className="col s12" style={{ paddingLeft: "11.250px" }}>
                            <h4>
                                Change Password
                            </h4>
                        </div>
                        <form noValidate onSubmit={this.onSubmit}>

                            <div className="input-field col s12">
                                <input
                                    onChange={this.onChange}
                                    value={this.state.current_password}
                                    error={errors.current_password}
                                    id="current_password"
                                    type="password"
                                    className={classnames("", {
                                        invalid: errors.current_password
                                    })}
                                />
                                <label htmlFor="current_password">Current Password</label>
                                <span className="red-text">{errors.current_password}</span>
                            </div>
                            <div className="input-field col s12">
                                <input
                                    onChange={this.onChange}
                                    value={this.state.password}
                                    error={errors.password}
                                    id="password"
                                    type="password"
                                    className={classnames("", {
                                        invalid: errors.password
                                    })}
                                />
                                <label htmlFor="password">New Password</label>
                                <span className="red-text">{errors.password}</span>
                            </div>
                            <div className="input-field col s12">
                                <input
                                    onChange={this.onChange}
                                    value={this.state.password2}
                                    error={errors.password2}
                                    id="password2"
                                    type="password"
                                    className={classnames("", {
                                        invalid: errors.password2
                                    })}
                                />
                                <label htmlFor="password2">Confirm New Password</label>
                                <span className="red-text">{errors.password2}</span>
                            </div>
                            <div className="col s12" style={{ paddingLeft: "11.250px" }}>
                                <Button variant="contained" color="primary"
                                    style={{
                                        width: "100%",
                                    }}
                                    type="submit"
                                    value="Sign up"
                                >
                                    Change Password
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

Register.propTypes = {
    updatePassword: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { updatePassword }
)(withRouter(Register));
