import React, { Component } from "react";
// This will require to npm install axios
import axios from 'axios';
import Button from '@material-ui/core/Button';
import classnames from "classnames";
import { Link } from "react-router-dom";
// import "./css/materialize.css"
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';

//import useOnclickOutside from "react-cool-onclickoutside";
// export default GoogleApiWrapper({
//   apiKey: "AIzaSyCap01JvkzX_YsjTjDUcAI_Ib5c20KHc98",
//   libraries: ["places"]
// })(MapContainer);
export default class updateOrder extends Component {
    // This is the constructor that stores the data.
    constructor(props) {
        super(props);

        this.onChangePersonName = this.onChangePersonName.bind(this);
        this.onChangePersonPosition = this.onChangePersonPosition.bind(this);
        this.onChangePersonLevel = this.onChangePersonLevel.bind(this);
        this.onChangePersonSecret = this.onChangePersonSecret.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        this.state = {
            contact_number: "",
            invoice_number: "",
            address: "",
            dropoff_address: "",
            contact_name: "",
            lat: "",
            lng: "",
            dropoff_lat: "",
            dropoff_lng: "",
            tracking_number: ""
        };
    }

    componentDidMount() {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const idn = params.get('id_number');
        console.log("params", idn)
        const result = axios.get("/api/record/orderDetails", { params: { invoice_number: idn } }).then((response) => {
            this.setState({
                contact_number: response.data.order[0].contact_number,
                invoice_number: response.data.order[0].invoice_number,
                address: response.data.order[0].address,
                dropoff_address: response.data.order[0].dropoff_address,
                contact_name: response.data.order[0].contact_name,
                lat: response.data.order[0].lat,
                lng: response.data.order[0].lng,
                dropoff_lat: response.data.order[0].dropoff_lat,
                dropoff_lng: response.data.order[0].dropoff_lng,
                tracking_number: idn
            })

            return (response.data.order);


        })
        console.log("resultttttttttt=>", result);
        // this.setState({
        //     contact_number: result[0].contact_number,
        //     invoice_number: result[0].invoice_number,
        //     address: result[0].address,
        //     dropoff_address: result[0].dropoff_address,
        //     contact_name: result[0].contact_name,
        //     lat: result[0].lat,
        //     lng: result[0].lng,
        //     dropoff_lat: result[0].dropoff_lat,
        //     dropoff_lng: result[0].dropoff_lng
        // })
    }
    // These methods will update the state properties.
    onChangePersonName(e) {
        this.setState({
            contact_number: e.target.value,
        });
    }

    onChangePersonPosition(e) {
        this.setState({
            invoice_number: e.target.value,
        });
    }
    onChangePersonSecret(e) {
        this.setState({
            contact_name: e.target.value,
        });
    }
    onChangePersonLevel(e) { //address
        this.setState({
            address: e.target.value,
        });
    }


    handleChange2 = dropoff_address => {
        //console.log(address);
        this.setState({ dropoff_address });
        this.setState({
            dropoff_address: dropoff_address,
        });
    };

    handleSelect2 = dropoff_address => {
        console.log(dropoff_address);
        this.setState({
            dropoff_address: dropoff_address,
        });
        geocodeByAddress(dropoff_address)
            .then(results => getLatLng(results[0]))
            .then(latLng => {
                this.setState({ dropoff_lat: latLng.lat, dropoff_lng: latLng.lng });
                console.log('Success', latLng);
            })
            .catch(error => console.error('Error', error));
        // alert(latLng);
    };


    handleChange = address => {
        //console.log(address);
        this.setState({ address });
        this.setState({
            address: address,
        });
    };

    handleSelect = address => {
        console.log(address);
        this.setState({
            address: address,
        });
        geocodeByAddress(address)
            .then(results => getLatLng(results[0]))
            .then(latLng => {
                this.setState({ lat: latLng.lat, lng: latLng.lng });
                console.log('Success', latLng);
            })
            .catch(error => console.error('Error', error));
        // alert(latLng);
    };

    // This function will handle the submission.
    onSubmit(e) {
        e.preventDefault();

        // When post request is sent to the create url, axios will add a new record(neworder) to the database.
        const neworder = {
            contact_number: this.state.contact_number,
            invoice_number: this.state.invoice_number,
            address: this.state.address,
            contact_name: this.state.contact_name,
            lat: this.state.lat,
            lng: this.state.lng,
            dropoff_address: this.state.dropoff_address,
            dropoff_lat: this.state.dropoff_lat,
            dropoff_lng: this.state.dropoff_lng,
            tracking_number: this.state.tracking_number
        };

        axios
            .post("/api/record/record/updateOrder", neworder)
            .then(res => this.props.history.push("./forms"));


        // We will empty the state after posting the data to the database
        this.setState({
            contact_number: "",
            invoice_number: "",
            address: "",
            dropoff_address: "",
            contact_name: "",
            lat: "",
            lng: "",
            dropoff_lat: "",
            dropoff_lng: ""
        });
        window.location.href = "./forms";

    }

    // This following section will display the form that takes the input from the user.
    render() {
        return (
            <div style={{ textAlign: "center" }}>
                <h5>Edit Order</h5><span style={{ display: "block" }}></span>
                <form onSubmit={this.onSubmit} style={{ textAlign: "left", display: "inline-block", maxWidth: "550px", width: "70%" }} >

                    <div className="input-field1 col s12">
                        <label>Invoice Number: </label>
                        <input
                            type="text"
                            className={classnames("", {
                                invalid: ""
                            })}
                            value={this.state.invoice_number}
                            onChange={this.onChangePersonPosition}
                        />
                    </div>

                    <PlacesAutocomplete
                        value={this.state.address}
                        onChange={this.handleChange}
                        onSelect={this.handleSelect}
                    >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                            <div className="input-field1 col s12">
                                <label>Pickup Address (Search...)</label>
                                <input
                                    {...getInputProps({

                                        className: classnames("", {
                                            invalid: ""
                                        }),
                                        id: 'inputi'
                                    })}
                                    value={this.state.address}
                                />
                                <div className="autocomplete-dropdown-container" style={{ position: "absolute", textAlign: "left", zIndex: "1", width: "100%", lineHeight: "26px" }}>
                                    {loading && <div>Loading...</div>}
                                    {suggestions.map(suggestion => {
                                        const className = suggestion.active
                                            ? 'suggestion-item--active'
                                            : 'suggestion-item';
                                        // inline style for demonstration purpose
                                        const style = suggestion.active
                                            ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                            : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                        return (
                                            <div
                                                {...getSuggestionItemProps(suggestion, {
                                                    className,
                                                    style,
                                                })}
                                            >
                                                <span>{suggestion.description}</span>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                    </PlacesAutocomplete>

                    <PlacesAutocomplete
                        value={this.state.dropoff_address}
                        onChange={this.handleChange2}
                        onSelect={this.handleSelect2}
                    >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                            <div className="input-field1 col s12">
                                <label>Drop off Address (Search...)</label>
                                <input
                                    {...getInputProps({

                                        className: classnames("", {
                                            invalid: ""
                                        }),
                                        id: 'inputi2'
                                    })}
                                    value={this.state.dropoff_address}
                                />
                                <div className="autocomplete-dropdown-container" style={{ position: "absolute", textAlign: "left", zIndex: "1", width: "100%", lineHeight: "26px" }}>
                                    {loading && <div>Loading...</div>}
                                    {suggestions.map(suggestion => {
                                        const className = suggestion.active
                                            ? 'suggestion-item--active'
                                            : 'suggestion-item';
                                        // inline style for demonstration purpose
                                        const style = suggestion.active
                                            ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                            : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                        return (
                                            <div
                                                {...getSuggestionItemProps(suggestion, {
                                                    className,
                                                    style,
                                                })}
                                            >
                                                <span>{suggestion.description}</span>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                    </PlacesAutocomplete>
                    <div className="input-field1 col s12">
                        <label className="form-check-label">Contact Name</label>
                        <input
                            className={classnames("", {
                                invalid: ""
                            })}
                            type="text"
                            value={this.state.contact_name}
                            onChange={this.onChangePersonSecret}
                        />
                    </div>
                    <div className="input-field1 col s12" >
                        <label>Phone Number: </label>
                        <input
                            type="text"
                            className={classnames("", {
                                invalid: ""
                            })}
                            value={this.state.contact_number}
                            onChange={this.onChangePersonName}
                        />
                    </div>
                    <div className="input-field1 col s12">
                        <Button variant="contained" color="primary" style={{ width: "100%" }}
                            type="submit"
                            value="Create Order"
                        >Update Order</Button>
                    </div>
                </form>
            </div>
        );
    }
}

