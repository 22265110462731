import React, { useState, useEffect } from "react";
// import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import MUIDataTable from "mui-datatables";
// import InputLabel from "@material-ui/core/InputLabel";
// import MenuItem from "@material-ui/core/MenuItem";
// import FormHelperText from "@material-ui/core/FormHelperText";
// import FormControl from "@material-ui/core/FormControl";
// import Select from "@material-ui/core/Select";
import axios from 'axios';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
// import "../App.css";
//import CustomToolbar from 'MUIDataTable/CustomToolbar';

export default function App() {
  const [responsive, setResponsive] = useState("vertical");
  const [tableBodyHeight, setTableBodyHeight] = useState("400px");
  const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("");
  const [rows, setData] = useState([]);
  useEffect(() => {
    const GetData = async () => {
      const result = await axios.get("/api/record/record", { params: { get: "get" } }).then((response) => { return (response.data.order); })//axios('http://localhost:51760/Api/Emp/employee');    
      setData(result);
    }
    GetData();
    console.log(rows);
  }, []);
  const columns = [{ name: "_id", label: "id", options: { display: "excluded" } }, { name: "name", label: "Name" }, { name: "username", label: "Username" }, { name: "email", label: "Email" },
  {
    name: "role", label: "Role Managment", options:
    {
      customBodyRender: (value, tableMeta) => {
        return (
          <span>
            <Button id={"Guest" + `${tableMeta.rowData[0]}`} onClick={(event) => roleChange(event, `${tableMeta.rowData[0]}`, value, "Guest")} size="small" variant={value === "Guest" ? "contained" : "outlined"} color="primary">Guest</Button>
            <Button id={"Supplier" + `${tableMeta.rowData[0]}`} onClick={(event) => roleChange(event, `${tableMeta.rowData[0]}`, value, "Supplier")} size="small" variant={value === "Supplier" ? "contained" : "outlined"} color="primary">Supplier</Button>
            <Button id={"Backend" + `${tableMeta.rowData[0]}`} onClick={(event) => roleChange(event, `${tableMeta.rowData[0]}`, value, "Backend")} size="small" variant={value === "Backend" ? "contained" : "outlined"} color="primary">Back Office</Button>
            <Button id={"Hero" + `${tableMeta.rowData[0]}`} onClick={(event) => roleChange(event, `${tableMeta.rowData[0]}`, value, "Hero")} size="small" variant={value === "Hero" ? "contained" : "outlined"} color="primary">Hero</Button>
            <Button id={"Admin" + `${tableMeta.rowData[0]}`} onClick={(event) => roleChange(event, `${tableMeta.rowData[0]}`, value, "Admin")} size="small" variant={value === "Admin" ? "contained" : "outlined"} color="primary">Admin</Button>

          </span>

        );
      }
    }
  }];

  const options = {
    filter: true,
    filterType: "dropdown",
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    searchOpen: true,
    textLabels: {
      body: {
        noMatch: 'Loading...',
      },
    },
  };
  const roleChange = (event, id, cur_role, new_role) => {
    //alert("id=>" + cur_role);
    event.preventDefault();
    if (cur_role === new_role) {
      return 1;
    }
    if (cur_role !== "Admin") {
      const newData = {
        _id: id,
        role: new_role
      };
      axios
        .post("/api/record/record/changerole", newData)
        .then((res) => setData(res.data.order));
      document.getElementById(new_role + id).setAttribute("class", "MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-containedSizeSmall MuiButton-sizeSmall");
      document.getElementById(cur_role + id).setAttribute("class", "MuiButtonBase-root MuiButton-root MuiButton-outlined MuiButton-outlinedPrimary MuiButton-outlinedSizeSmall MuiButton-sizeSmall");
    } else {
      alert("Admin can not be changed.");
    }
  };


  return (
    <div>
      <h5 style={{ display: "contents" }}> Users</h5> <Link to="./addUser" ><Icon style={{ marginLeft: 20 }} color="primary" title="Add New User" >add_circle</Icon></Link>
      <div>
        <React.Fragment>
          <MUIDataTable
            title={""}
            data={rows}
            columns={columns}
            options={options}
          />
        </React.Fragment>
      </div>

    </div>
  );
}

//ReactDOM.render(<App />, document.getElementById("root"));
