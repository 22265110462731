import React, { Component } from "react";
// This will require to npm install axios
import axios from 'axios';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import classnames from "classnames";
import { Link } from "react-router-dom";
import { loginUser } from "../actions/authActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// import "./css/materialize.css"
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
var file = "";
//import useOnclickOutside from "react-cool-onclickoutside";
// export default GoogleApiWrapper({
//   apiKey: "AIzaSyCap01JvkzX_YsjTjDUcAI_Ib5c20KHc98",
//   libraries: ["places"]
// })(MapContainer);
class Create extends Component {
    // This is the constructor that stores the data.
    constructor() {
        super();
        // const { user } = this.props.auth;
        this.state = {
            name: "",
            address: "",
            profile_pic: "",
            contact_number: "",
            errors: {},
        };
    }

    componentDidMount() {
        const { user } = this.props.auth;

        const newUser = {
            id: user.id
        };
        var result = axios
            .post("/api/record/record/updateProfile", newUser)
            .then((response) => {
                this.setState({ name: response.data.order[0].name });
                this.setState({ address: response.data.order[0].address });
                this.setState({ contact_number: response.data.order[0].contact_number });
                // this.setState({ profile_pic: response.data.order[0].profile_pic });
                // console.log("nameeeeee=>", this.state.name)

            });
        // this.setState({
        //     name: result.name
        // });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
    }

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    onSubmit = e => {
        e.preventDefault();
        const { user } = this.props.auth;
        const data = new FormData();
        data.append("avatar", this.state.profile_pic);
        data.append("name", this.state.name);
        data.append("id", user.id);
        data.append("address", this.state.address);
        data.append("contact_number", this.state.contact_number);
        if (this.state.profile_pic)
            data.append("profile_pic", "yes");
        else
            data.append("profile_pic", "");
        axios({
            method: 'post',
            url: '/api/record/record/updateProfile',
            data: data,
            config: { headers: { 'Content-Type': 'multipart/form-data' } }
        }).then(res => setTimeout(() => { this.props.history.push("./dashboard") }, 5000));
    };

    dataURIToBlob = dataURI => {
        const splitDataURI = dataURI.split(",");
        const byteString =
            splitDataURI[0].indexOf("base64") >= 0
                ? atob(splitDataURI[1])
                : decodeURI(splitDataURI[1]);
        const mimeString = splitDataURI[0].split(":")[1].split(";")[0];
        const ia = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);
        return new Blob([ia], { type: mimeString });
    };

    handlePhoto = e => {
        file = e.target.files[0];
        // this.setState({ profile_pic: e.target.files[0] })
        var reader = new FileReader();
        reader.onload = (e) => {
            var img = document.createElement("img");
            img.onload = () => {
                var canvas = document.createElement('canvas');
                var ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0);

                var MAX_WIDTH = 300;
                var MAX_HEIGHT = 300;
                var width = img.width;
                var height = img.height;
                // alert(width);
                if (width > height) {
                    if (width > MAX_WIDTH) {
                        height *= MAX_WIDTH / width;
                        width = MAX_WIDTH;
                    }
                } else {
                    if (height > MAX_HEIGHT) {
                        width *= MAX_HEIGHT / height;
                        height = MAX_HEIGHT;
                    }
                }
                canvas.width = width;
                canvas.height = height;
                var ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0, width, height);
                var dataurl = canvas.toDataURL("image/png");
                this.setState({ profile_pic: this.dataURIToBlob(dataurl) });
            }
            img.src = e.target.result;

        }
        reader.readAsDataURL(file);
    }
    handleChange2 = address => {
        //console.log(address);
        this.setState({ address });
        this.setState({
            address: address,
        });
    };

    handleSelect2 = address => {
        console.log(address);
        this.setState({
            address: address,
        });
        geocodeByAddress(address)
            .then(results => getLatLng(results[0]))
            .then(latLng => {
                this.setState({ dropoff_lat: latLng.lat, dropoff_lng: latLng.lng });
                console.log('Success', latLng);
            })
            .catch(error => console.error('Error', error));
        // alert(latLng);
    };

    render() {
        const { errors } = this.state;

        return (
            <div className="container">
                <div className="row">
                    <div className="col s8 offset-s2">
                        <Link to="./dashboard">
                            <i className="material-icons left"></i>Cancel
                        </Link>
                        <div className="col s12" style={{ paddingLeft: "11.250px" }}>
                            <h4>
                                Edit Profile
                            </h4>
                        </div>
                        <form noValidate onSubmit={this.onSubmit}>
                            <div className="input-field col s12">
                                <TextField
                                    onChange={this.onChange}
                                    value={this.state.name}
                                    error={errors.name}
                                    id="name"
                                    label="Name"
                                    type="text"
                                    className={classnames("", {
                                        invalid: errors.name
                                    })}
                                    style={{ width: "100%" }}
                                />
                                <span className="red-text">{errors.name}</span>
                            </div>
                            <PlacesAutocomplete
                                value={this.state.address}
                                onChange={this.handleChange2}
                                onSelect={this.handleSelect2}
                            >
                                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                    <div className="input-field col s12">
                                        {/* <label>Adress</label> */}
                                        <TextField
                                            {...getInputProps({

                                                className: classnames("", {
                                                    invalid: ""
                                                }),
                                                id: 'address',
                                                label: 'Address'
                                            })}
                                            value={this.state.address}
                                            style={{ width: "100%" }}
                                        />
                                        <div className="autocomplete-dropdown-container" style={{ position: "absolute", textAlign: "left", zIndex: "1", width: "100%", lineHeight: "26px" }}>
                                            {loading && <div>Loading...</div>}
                                            {suggestions.map(suggestion => {
                                                const className = suggestion.active
                                                    ? 'suggestion-item--active'
                                                    : 'suggestion-item';
                                                // inline style for demonstration purpose
                                                const style = suggestion.active
                                                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                return (
                                                    <div
                                                        {...getSuggestionItemProps(suggestion, {
                                                            className,
                                                            style,
                                                        })}
                                                    >
                                                        <span>{suggestion.description}</span>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                )}
                            </PlacesAutocomplete>
                            {/* <div className="input-field col s12">
                                <TextField
                                    onChange={this.onChange}
                                    value={this.state.address}
                                    error={errors.address}
                                    id="address"
                                    label="Address"
                                    type="text"
                                    className={classnames("", {
                                        invalid: errors.address
                                    })}
                                    style={{ width: "100%" }}
                                />
                                <span className="red-text">{errors.address}</span>
                            </div> */}
                            <div className="input-field col s12">
                                <TextField
                                    onChange={this.onChange}
                                    value={this.state.contact_number}
                                    error={errors.contact_number}
                                    id="contact_number"
                                    label="Contact Number"
                                    type="text"
                                    className={classnames("", {
                                        invalid: errors.contact_number
                                    })}
                                    style={{ width: "100%" }}
                                />
                                <span className="red-text">{errors.contact_number}</span>
                            </div>
                            <div className="input-field col s12">
                                <form encType='multipart/form-data'>
                                    <label htmlFor="photo">Profile Picture</label><br />
                                    <input
                                        type="file"
                                        accept=".png, .jpg, .jpeg"
                                        // label="Profile Picture"
                                        name="photo"
                                        id="photo"
                                        onChange={this.handlePhoto}
                                    // enctype="multipart/form-data"
                                    />

                                    <span className="red-text">{errors.contact_number}</span>
                                </form>
                            </div>


                            <div className="col s12" style={{ paddingLeft: "11.250px" }}>
                                <Button variant="contained" color="primary"
                                    style={{
                                        width: "100%",
                                    }}
                                    type="submit"
                                    value="Sign up"
                                >
                                    Submit
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

Create.propTypes = {
    loginUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { loginUser }
)(Create);
