import React, { Component } from "react";
import { BrowserRouter as Router, Link, Redirect, useHistory, useParams } from "react-router-dom";
import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";

import { setCurrentUser, logoutUser } from "./actions/authActions";
import { Provider } from "react-redux";
import store from "./store";

import Navbar from "./components/layout/Navbar";


import "./App.css";
// const params = useParams();
// Check for token to keep user logged in

if (localStorage.jwtToken) {
  var path_arr = (window.location.pathname).split("/");
  var index = path_arr[1];
  // Set auth token header auth
  if (path_arr[2] === 'login' && index > 0)
    index--;
  if (path_arr.length < 3)
    index = 0;
  const token = JSON.parse(localStorage.jwtToken);
  // console.log("tokennnnnn===>", token);
  if (index < token.length) {
    setAuthToken(token[index]);
    // Decode token and get user info and exp
    const decoded = jwt_decode(token[index]);
    // Set user and isAuthenticated
    store.dispatch(setCurrentUser(decoded));

    // Check for expired token
    const currentTime = Date.now() / 1000; // to get in milliseconds
    if (decoded.exp < currentTime) {
      // Logout user
      store.dispatch(logoutUser());

      // Redirect to login
      window.location.href = "./login";
    }
  }
}
class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Router>
          <div className="App">
            <Navbar />
            {/* <Route exact path="/register" component={Register} />
            <Route exact path="/login" component={Login} /> */}
            {/* <Route exact path="/forms" component={Forms} /> */}
            {/* <Switch>
              <PrivateRoute exact path="/dashboard" component={Dashboard} />
              <PrivateRoute exact path="/" component={Forms} />
              <PrivateRoute exact path="/create" component={Create} />
              <PrivateRoute exact path="/forms" component={Users} />
            </Switch> */}
          </div>
        </Router>
      </Provider>
    );
  }
}
export default App;
