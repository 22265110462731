import * as React from 'react';
import Box from '@mui/material/Box';
import Icon from '@mui/material/Icon';
import MdPhone from '@mui/icons-material/Phone';
import Chip from '@mui/material/Chip';



export default function FontAwesomeIconSize() {


    return (
        <Box
            sx={{
                '& > :not(style)': {
                    m: 1,
                },
            }}
        >
            <Chip icon={<MdPhone />} label="Call me" />

        </Box>
    );
}

