import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import isEmpty from 'lodash.isempty';
import MUIDataTable from "mui-datatables";
// import Select from "@material-ui/core/Select";
import Select from '@mui/material/Select';
import axios from 'axios';
import Icon from '@material-ui/core/Icon';
import PropTypes from "prop-types";
import { connect } from "react-redux";

import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";


import MenuIcon from "@material-ui/icons/Message";
import { StyledMenu, StyledMenuItem } from "./StyledMenu";
// import Select from 'react-select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@material-ui/core/Button';
import { ListItemIcon, ListItemText, IconButton } from "@material-ui/core";
//import "../App.css";
var usersArray = [];
var tempArray = [];
var ArrayIDs = [];
var option_value = '';

const App = ({ component: Component, auth }) => {
  const [responsive, setResponsive] = useState("vertical");
  const [tableBodyHeight, setTableBodyHeight] = useState("400px");
  const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [anchorIndex, setAnchorIndex] = useState("");
  const [anchorElement, setAnchorElement] = useState([]);
  const [anchorIndex2, setAnchorIndex2] = useState("");
  const [anchorElement2, setAnchorElement2] = useState([]);
  const [imgg, setImgg] = useState({});

  const myinputref = useRef(null);
  const { user } = auth;

  const handleClickMenu = (e) => {
    setAnchorEl(e.currentTarget);
    // document.getElementById(anchorIndex).open = true;
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    tempArray[anchorIndex] = false;
    setAnchorElement(tempArray);
    tempArray = [];

  };

  const handleCloseMenu2 = () => {
    setAnchorEl2(null);
    tempArray[anchorIndex2] = false;
    setAnchorElement2(tempArray);
    tempArray = [];
    document.getElementById("text_" + anchorIndex2).style.display = 'none';
  };

  const sendSMS = (id_number) => {
    // alert(document.getElementById("sms_number" + id_number).value)
    const result = axios.get("/api/record/sendMessage", { params: { "id_number": id_number, "mobile_number": document.getElementById("sms_number" + id_number).value } }).then((response) => { return (response.data.order); })
    document.getElementById("sms_number" + id_number).value = "";
    setAnchorEl(null);
    tempArray[anchorIndex] = false;
    setAnchorElement(tempArray);
    tempArray = [];
  }

  const addNew = (id_number) => {
    document.getElementById("text_" + id_number).style.display = 'inline';
  }

  const submitNewComment = (id_number) => {
    // document.getElementById("text_" + id_number).style.display = 'inline';
    const newData = {
      _id: id_number,
      text: document.getElementById("mytext_" + id_number).value,
      uid: user.id
    };
    axios.post("/api/record/record/addcomment", newData)
      .then((res) => setData(res.data.order));
    handleCloseMenu2();
  }


  const assignorder = (optvalue, order_id) => {
    setAnchorIndex(order_id);
    option_value = optvalue;
    // console.log("Order_id", order_id)
    // alert(order_id);
    myinputref.current.click();
    return false;
  }

  const [rows, setData] = useState([]);
  useEffect(() => {
    const GetData = async () => {
      const result = await axios.get("/api/record/forms", { params: { get: "get" } }).then((response) => { return (response.data.order); })//axios('http://localhost:51760/Api/Emp/employee');    
      setData(result);
      ArrayIDs = await result.map(currentVal => (currentVal._id));
    }
    GetData();
    console.log(rows);
  }, []);

  const [usersData, setUserData] = useState([]);
  useEffect(() => {
    const GetUserData = async () => {
      const result = await axios.get("/api/record/record", { params: { get: "get" } }).then((response) => { return (response.data.order); })//axios('http://localhost:51760/Api/Emp/employee');    
      setUserData(result);
      usersArray = result.reduce(function (accum, currentVal) {
        accum[currentVal._id] = currentVal.name;
        return accum;
      }, {});
      console.log(usersArray);
    }
    GetUserData();
    console.log("rows=======", rows);
  }, []);

  const aquaticCreatures = usersData.filter(person => person.role === "Hero").map(filteredPerson => (
    { label: filteredPerson.name, value: filteredPerson._id }
  ))

  // {name:"invoice_number",label:"Invoice No."}, 
  const columns = [{ name: "_id", label: "id", options: { display: "excluded" } },
  { name: "tracking_number", label: "Tracking No." }, { name: "invoice_number", label: "Invoice No." }, { name: "address", label: "Location" },
  { name: "pickedup_img", label: "id3", options: { display: "excluded" } },
  { name: "delivered_img", label: "id4", options: { display: "excluded" } },
  { name: "pickedup_date", label: "id5", options: { display: "excluded" } },
  { name: "delivered_date", label: "id6", options: { display: "excluded" } },
  { name: "assignedto", label: "id7", options: { display: "excluded" } },
  { name: "assigningDate", label: "id8", options: { display: "excluded" } },
  { name: "dropoff_address", label: "Drop off Location" },
  {
    name: "assignedDate", label: "Assigned Date", options: {
      customBodyRender: (value, tableMeta, updateValue) => {
        return (!isEmpty(value) ? (new Date(value)).toLocaleString() : "---")
      }
    }
  },
  { name: "contact_name", label: "Contact Name." },
  {
    name: "status", label: "Status", options: {
      customBodyRender: (value, tableMeta, updateValue) => {
        return (<span id={`${tableMeta.rowData[0]}`} >{value}<br />
          {!isEmpty(tableMeta.rowData[7]) ? new Date(tableMeta.rowData[7]).toLocaleString() : !isEmpty(tableMeta.rowData[6]) ? new Date(tableMeta.rowData[6]).toLocaleString() : ""}
        </span>);
      }
    }
  },
  { name: "comments", label: "id13", options: { display: "excluded" } },
  {
    name: "status",
    label: "Action",
    options: {
      viewColumns: false,
      filter: false,
      customBodyRender: (value, tableMeta, updateValue) => {
        return (
          <div style={{ minWidth: "180px" }}>
            <IconButton
              onClick={(e) => {
                handleClickMenu(e);
                setAnchorIndex(`${tableMeta.rowData[0]}`);
                tempArray[`${tableMeta.rowData[0]}`] = true;
                setAnchorElement(tempArray);
                tempArray = [];
                if (typeof document.getElementsByClassName("css-1uccc91-singleValue")[0] !== 'undefined') {
                  document.getElementsByClassName("css-1uccc91-singleValue")[0].innerHTML = "Select...";
                }
              }}
            >
              <MenuIcon />
            </IconButton>
            {rows.length > 0 && <StyledMenu
              id={`${tableMeta.rowData[0]}`}
              key={tableMeta.rowData[0]}
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorElement[`${tableMeta.rowData[0]}`])}
              onClose={handleCloseMenu}
            >
              <div style={{ width: "300px", minHeight: "180px", display: "block", padding: "10px" }}>
                <h6>&#8288;Enter Number/Email To Send the Link</h6>
                <input id={"sms_number" + tableMeta.rowData[1]} name="sms_number" />
                <Button id="done_assigning" variant="contained" color="primary"
                  onClick={(e) => { sendSMS(tableMeta.rowData[1]) }}>Send Link</Button>

              </div>
            </StyledMenu>}
            {rows.length > 0 && <StyledMenu
              id={"com_" + `${tableMeta.rowData[0]}`}
              key={"com_" + tableMeta.rowData[0]}
              anchorEl={anchorEl2}
              keepMounted
              open={Boolean(anchorElement2["com_" + `${tableMeta.rowData[0]}`])}
              onClose={handleCloseMenu2}
            >
              <div style={{ width: "300px", minHeight: "180px", display: "block", padding: "10px" }}>
                <h5>&#8288;Comments</h5>
                {tableMeta.rowData[14].length > 0 ? tableMeta.rowData[14].map((cmnt, indx) => {
                  if (!isEmpty(cmnt))
                    return (
                      <div style={{ display: 'list-item' }}><b>({usersArray[cmnt.creator_id]}): </b>{cmnt.comment_text}</div>
                    );
                }) : 'No Comments'}
                <div id={"text_" + tableMeta.rowData[0]} s style={{ display: 'none' }}>
                  <h6>Add Comment</h6>
                  <input type='text' id={"mytext_" + tableMeta.rowData[0]} name="sms_number2" />
                  <Button id="done_assigning" variant="contained" color="primary"
                    onClick={(e) => { submitNewComment(tableMeta.rowData[0]) }}>Submit</Button>
                  <br />
                </div>
                <br />
                <Button id="done_assigning" variant="contained" color="primary"
                  onClick={(e) => { addNew(tableMeta.rowData[0]) }}>Add New Comment</Button>

              </div>
            </StyledMenu>}
            <Link to={'./updateOrder?id_number=' + `${tableMeta.rowData[1]}`} style={{ marginRight: "10px" }}>Edit</Link>
            <a onClick={(e) => { //Comments clicked

              setAnchorEl2(e.currentTarget)
              setAnchorIndex2(`${tableMeta.rowData[0]}`);
              tempArray["com_" + `${tableMeta.rowData[0]}`] = true;
              setAnchorElement2(tempArray);
              tempArray = [];

            }}>Comments</a>
            <Select id="react_select_idd" style={{ width: "100px" }} onChange={(opt) => assignorder(opt.target.value, `${tableMeta.rowData[0]}`)}
              labelId="demo-simple-select-label"
              value={value}
            // label="Select Status"
            // displayEmpty
            >
              <MenuItem disabled value="Waiting">Waiting</MenuItem>
              <MenuItem disabled value="Assigned">Assigned</MenuItem>
              <MenuItem value="Picked">Picked up</MenuItem>
              <MenuItem value="Delivered">Delivered</MenuItem>
              <MenuItem value="Cancelled">Cancelled</MenuItem>
            </Select>

          </div>
        );
      }
    }
  }];

  const options = {
    filter: true,
    filterType: "dropdown",
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    onRowsDelete: (e) => { deleteOrders(e.data) },
    // selectableRows: 'single',
    searchOpen: true,
    textLabels: {
      body: {
        noMatch: 'Loading...',
      },
    },
    expandableRows: true,
    expandableRowsHeader: false,
    expandableRowsOnClick: true,
    renderExpandableRow: (rowData, rowMeta) => {
      const colSpan = rowData.length + 1;
      return (
        <TableRow >
          <TableCell style={{ paddingLeft: "10%" }} colSpan={colSpan}>
            {/* <div style={{ width: "300px", minHeight: "180px", display: "block", padding: "10px" }}> */}
            <h6><b>Order History</b></h6>
            <div style={{ display: 'flex', alignItems: "center" }}>
              <h7><b>Assigned To: </b>{!isEmpty(usersArray[rowData[8]]) ? usersArray[rowData[8]] : "Not assigned"}</h7>
              <h7 style={{ paddingLeft: "20px" }}><b>Assigned On: </b>{!isEmpty(rowData[9]) ? new Date(rowData[9]).toLocaleString() : ""}</h7>
              <h7 style={{ paddingLeft: "20px" }}><b>Sprint Date: </b>{!isEmpty(rowData[10]) ? new Date(rowData[10]).toLocaleString() : ""}</h7>
              <h7 style={{ paddingLeft: "20px" }}><b>Pickedup On: </b>{!isEmpty(rowData[6]) ? new Date(rowData[6]).toLocaleString() : ""}</h7>
              {!isEmpty(rowData[4]) &&
                <img style={{ width: "120px", maxHeight: "120px" }} src={'../uploads/' + rowData[4]} onError={(e) => { e.target.onerror = null; e.target.src = '../images/test.png' }} alt={"Pickup image"} />}
              <h7 style={{ paddingLeft: "20px" }}> <b>Delivered On: </b>{!isEmpty(rowData[7]) ? new Date(rowData[7]).toLocaleString() : ""}</h7>
              {!isEmpty(rowData[5]) &&
                <img style={{ width: "120px", maxHeight: "120px" }} src={'../uploads/' + rowData[5]} onError={(e) => { e.target.onerror = null; e.target.src = '../images/test.png' }} alt={"Delivered image"} />}
              {/* <Select id="react_select_id" style={{ height: "200px", overflow: "scroll" }} options={aquaticCreatures} onChange={(opt) => assignorder(opt.value, anchorIndex)} /> */}
              {/* </div> */}
            </div>
          </TableCell>
        </TableRow>
      );
    },
    onRowExpansionChange: (curExpanded, allExpanded, rowsExpanded) => console.log(curExpanded, allExpanded, rowsExpanded)
  };

  const deleteOrders = (data_indexes) => {
    console.log(data_indexes);
    const delete_ids = data_indexes.map(currentVal => (ArrayIDs[currentVal.dataIndex]));
    console.log(delete_ids);
    const newData = {
      _id: delete_ids,
    };
    axios
      .post("/api/record/record/delOrder", newData)
      .then((res) => setData(res.data.order));
    //ArrayIDs
  }

  const dataURIToBlob = (dataURI) => {
    const splitDataURI = dataURI.split(",");
    const byteString =
      splitDataURI[0].indexOf("base64") >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(":")[1].split(";")[0];
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);
    return new Blob([ia], { type: mimeString });
  };

  const handlePhoto = e => {
    if (e.target.files[0]) {
      var file = e.target.files[0];
      var reader = new FileReader();
      reader.onload = (e) => {
        var img = document.createElement("img");
        img.onload = () => {
          var canvas = document.createElement('canvas');
          var ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0);

          var MAX_WIDTH = 300;
          var MAX_HEIGHT = 300;
          var width = img.width;
          var height = img.height;
          // alert(width);
          if (width > height) {
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width;
              width = MAX_WIDTH;
            }
          } else {
            if (height > MAX_HEIGHT) {
              width *= MAX_HEIGHT / height;
              height = MAX_HEIGHT;
            }
          }
          canvas.width = width;
          canvas.height = height;
          var ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, width, height);
          var dataurl = canvas.toDataURL("image/png");
          const data = new FormData();
          // const file = e.target.files[0];
          data.append("avatar", dataURIToBlob(dataurl)); // <-- use "avatar" instead of "file" here
          data.append("idd", anchorIndex);
          data.append("status", option_value);
          axios({
            method: 'post',
            url: '/api/record/upload',
            data: data,
            config: { headers: { 'Content-Type': 'multipart/form-data' } }
          }).then((res) => {
            setTimeout(() => {
              setData(res.data.order)
            }, 2000);
            setAnchorEl(null);
            document.getElementById("photo").value = "";
          });
        }
        img.src = e.target.result;

      }
      reader.readAsDataURL(file);

    }
  };

  return (
    <div className="App">
      <div style={{ display: "none" }}>
        <form encType='multipart/form-data'>
          <input
            type="file"
            accept=".png, .jpg, .jpeg"
            name="photo"
            id="photo"
            onChange={handlePhoto}
            ref={myinputref}
          // enctype="multipart/form-data"
          />
        </form>
      </div>
      <h5 style={{ display: "contents" }}> Orders</h5> <Link to="./create" ><Icon style={{ marginLeft: 20 }} color="primary" title="Add New Order" >add_circle</Icon></Link>
      <div>
        {/* <React.Fragment>  */}
        <MUIDataTable
          title={""}
          data={rows}
          columns={columns}
          options={options}
        />
        {/* </React.Fragment> */}
      </div>

    </div>
  );
}

App.propTypes = {
  auth: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool
};

const mapStateToProps = state => ({
  auth: state.auth,
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps)(App);

//ReactDOM.render(<App />, document.getElementById("root"));
